//TODO: Add types in node version update branch (already defined there)
// @ts-ignore
export const sortResults = ({ filterResults, sortingValue } = {}) => {
    // @ts-ignore
    return filterResults.sort((resultA, resultB) => {
        if (resultA[sortingValue] < resultB[sortingValue]) {
            return -1;
        }

        if (resultA[sortingValue] > resultB[sortingValue]) {
            return 1;
        }

        return 0;
    });
};
