import { State } from "./types";

export const namespaced = true;

export const state: State = {
    contactModuleInitialized: false,
    contactModuleOpen: false,
    contactTopic: "contact_0",
};

export const getters = {
    contactModuleInitialized: (state: State): boolean => {
        return state.contactModuleInitialized;
    },

    contactModuleOpen: (state: State): boolean => {
        return state.contactModuleOpen;
    },

    contactTopic: (state: State): string => {
        return state.contactTopic;
    },
};

export const mutations = {
    SET_CONTACT_MODULE_INITIALIZED(state: State, initialized: boolean) {
        state.contactModuleInitialized = initialized;
    },

    SET_CONTACT_MODULE_OPEN(state: State, open: boolean) {
        state.contactModuleOpen = open;
    },

    SET_CONTACT_TOPIC_ID(state: State, topic: string) {
        state.contactTopic = topic;
    },
};

export const actions = {
    setContactModuleInitialized({ commit }: any, initialized: boolean) {
        commit("SET_CONTACT_MODULE_INITIALIZED", initialized);
    },

    setContactModuleOpen({ commit }: any, open: boolean) {
        commit("SET_CONTACT_MODULE_OPEN", open);
    },

    setContactTopic({ commit }: any, topic: string) {
        commit("SET_CONTACT_TOPIC_ID", topic);
    },
};
