// @ts-ignore
import Vue from "vue/dist/vue.min";
import Vuex from "vuex";

import * as mainStore from "./modules/mainStore";
import * as filterStore from "./modules/filterStore";
import * as teaserStore from "./modules/teaserStore";
import * as contactStore from "./modules/contactStore";
import * as globalSearchStore from "./modules/globalSearchStore";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        mainStore,
        filterStore,
        teaserStore,
        contactStore,
        globalSearchStore,
    },
});
