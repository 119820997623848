export const searchResultsEmpty = {
    errormessage: "",
    errornumber: "0",
    success: true,
    result: {
        took: "23",
        timed_out: false,
        _shards: { total: "5", successful: "5", skipped: "0", failed: "0" },
        hits: {
            total: "0",
            max_score: "0",
            hits: [],
        },
        aggregations: {
            range: {
                buckets: {
                    365: { from: "1644710400000", from_as_string: "2022-02-13T00:00:00Z", doc_count: "188" },
                    90: { from: "1668470400000", from_as_string: "2022-11-15T00:00:00Z", doc_count: "68" },
                    30: { from: "1673654400000", from_as_string: "2023-01-14T00:00:00Z", doc_count: "47" },
                    7: { from: "1675641600000", from_as_string: "2023-02-06T00:00:00Z", doc_count: "10" },
                },
            },
            categories: {
                doc_count_error_upper_bound: "0",
                sum_other_doc_count: "0",
                buckets: [
                    { key: "29", doc_count: "747" },
                    { key: "420", doc_count: "703" },
                    { key: "154", doc_count: "648" },
                    { key: "146", doc_count: "587" },
                    { key: "150", doc_count: "559" },
                    { key: "132", doc_count: "533" },
                    { key: "636", doc_count: "481" },
                    { key: "133", doc_count: "448" },
                    { key: "422", doc_count: "407" },
                    { key: "140", doc_count: "398" },
                    { key: "181", doc_count: "384" },
                    { key: "324", doc_count: "346" },
                    { key: "340", doc_count: "264" },
                    { key: "421", doc_count: "244" },
                    { key: "446", doc_count: "237" },
                    { key: "135", doc_count: "235" },
                    { key: "163", doc_count: "220" },
                    { key: "488", doc_count: "192" },
                    { key: "676", doc_count: "185" },
                    { key: "490", doc_count: "180" },
                    { key: "328", doc_count: "173" },
                    { key: "326", doc_count: "158" },
                    { key: "149", doc_count: "157" },
                    { key: "630", doc_count: "155" },
                    { key: "216", doc_count: "154" },
                    { key: "222", doc_count: "154" },
                    { key: "185", doc_count: "135" },
                    { key: "205", doc_count: "128" },
                    { key: "225", doc_count: "125" },
                    { key: "414", doc_count: "124" },
                    { key: "186", doc_count: "110" },
                    { key: "344", doc_count: "109" },
                    { key: "153", doc_count: "103" },
                    { key: "689", doc_count: "101" },
                    { key: "493", doc_count: "100" },
                    { key: "642", doc_count: "99" },
                    { key: "134", doc_count: "95" },
                    { key: "576", doc_count: "95" },
                    { key: "157", doc_count: "94" },
                    { key: "138", doc_count: "89" },
                    { key: "519", doc_count: "89" },
                    { key: "624", doc_count: "89" },
                    { key: "325", doc_count: "86" },
                    { key: "415", doc_count: "86" },
                    { key: "346", doc_count: "85" },
                    { key: "425", doc_count: "85" },
                    { key: "215", doc_count: "84" },
                    { key: "437", doc_count: "77" },
                    { key: "677", doc_count: "75" },
                    { key: "139", doc_count: "71" },
                    { key: "159", doc_count: "71" },
                    { key: "160", doc_count: "70" },
                    { key: "673", doc_count: "69" },
                    { key: "210", doc_count: "67" },
                    { key: "337", doc_count: "66" },
                    { key: "183", doc_count: "64" },
                    { key: "345", doc_count: "64" },
                    { key: "394", doc_count: "64" },
                    { key: "459", doc_count: "64" },
                    { key: "675", doc_count: "63" },
                    { key: "208", doc_count: "62" },
                    { key: "522", doc_count: "62" },
                    { key: "674", doc_count: "62" },
                    { key: "158", doc_count: "60" },
                    { key: "385", doc_count: "60" },
                    { key: "423", doc_count: "60" },
                    { key: "202", doc_count: "58" },
                    { key: "578", doc_count: "57" },
                    { key: "206", doc_count: "56" },
                    { key: "359", doc_count: "54" },
                    { key: "226", doc_count: "53" },
                    { key: "246", doc_count: "53" },
                    { key: "143", doc_count: "50" },
                    { key: "342", doc_count: "50" },
                    { key: "347", doc_count: "50" },
                    { key: "318", doc_count: "49" },
                    { key: "577", doc_count: "49" },
                    { key: "200", doc_count: "48" },
                    { key: "692", doc_count: "48" },
                    { key: "350", doc_count: "46" },
                    { key: "319", doc_count: "45" },
                    { key: "646", doc_count: "44" },
                    { key: "161", doc_count: "43" },
                    { key: "142", doc_count: "42" },
                    { key: "217", doc_count: "42" },
                    { key: "207", doc_count: "41" },
                    { key: "362", doc_count: "40" },
                    { key: "195", doc_count: "39" },
                    { key: "333", doc_count: "39" },
                    { key: "349", doc_count: "39" },
                    { key: "170", doc_count: "37" },
                    { key: "348", doc_count: "37" },
                    { key: "518", doc_count: "37" },
                    { key: "610", doc_count: "37" },
                    { key: "322", doc_count: "36" },
                    { key: "644", doc_count: "36" },
                    { key: "647", doc_count: "36" },
                    { key: "654", doc_count: "36" },
                    { key: "171", doc_count: "35" },
                    { key: "504", doc_count: "35" },
                    { key: "632", doc_count: "34" },
                    { key: "643", doc_count: "34" },
                    { key: "204", doc_count: "33" },
                    { key: "537", doc_count: "33" },
                    { key: "538", doc_count: "33" },
                    { key: "167", doc_count: "32" },
                    { key: "584", doc_count: "32" },
                    { key: "619", doc_count: "32" },
                    { key: "625", doc_count: "32" },
                    { key: "686", doc_count: "32" },
                    { key: "352", doc_count: "31" },
                    { key: "600", doc_count: "31" },
                    { key: "618", doc_count: "31" },
                    { key: "179", doc_count: "30" },
                    { key: "180", doc_count: "30" },
                    { key: "327", doc_count: "30" },
                    { key: "631", doc_count: "30" },
                    { key: "634", doc_count: "30" },
                    { key: "156", doc_count: "29" },
                    { key: "239", doc_count: "29" },
                    { key: "341", doc_count: "29" },
                    { key: "447", doc_count: "28" },
                    { key: "456", doc_count: "28" },
                    { key: "495", doc_count: "28" },
                    { key: "237", doc_count: "27" },
                    { key: "316", doc_count: "27" },
                    { key: "454", doc_count: "27" },
                    { key: "645", doc_count: "27" },
                    { key: "662", doc_count: "27" },
                    { key: "244", doc_count: "26" },
                    { key: "287", doc_count: "26" },
                    { key: "627", doc_count: "26" },
                    { key: "652", doc_count: "26" },
                    { key: "354", doc_count: "25" },
                    { key: "368", doc_count: "25" },
                    { key: "453", doc_count: "25" },
                    { key: "637", doc_count: "25" },
                    { key: "297", doc_count: "24" },
                    { key: "440", doc_count: "24" },
                    { key: "462", doc_count: "24" },
                    { key: "687", doc_count: "24" },
                    { key: "147", doc_count: "23" },
                    { key: "227", doc_count: "23" },
                    { key: "370", doc_count: "23" },
                    { key: "439", doc_count: "23" },
                    { key: "441", doc_count: "23" },
                    { key: "445", doc_count: "23" },
                    { key: "343", doc_count: "22" },
                    { key: "358", doc_count: "22" },
                    { key: "575", doc_count: "22" },
                    { key: "601", doc_count: "22" },
                    { key: "611", doc_count: "22" },
                    { key: "162", doc_count: "21" },
                    { key: "315", doc_count: "21" },
                    { key: "320", doc_count: "20" },
                    { key: "321", doc_count: "20" },
                    { key: "335", doc_count: "20" },
                    { key: "363", doc_count: "20" },
                    { key: "365", doc_count: "20" },
                    { key: "403", doc_count: "20" },
                    { key: "442", doc_count: "20" },
                    { key: "443", doc_count: "20" },
                    { key: "481", doc_count: "20" },
                    { key: "529", doc_count: "19" },
                    { key: "357", doc_count: "18" },
                    { key: "361", doc_count: "18" },
                    { key: "203", doc_count: "17" },
                    { key: "372", doc_count: "17" },
                    { key: "388", doc_count: "17" },
                    { key: "530", doc_count: "17" },
                    { key: "603", doc_count: "17" },
                    { key: "648", doc_count: "17" },
                    { key: "369", doc_count: "16" },
                    { key: "397", doc_count: "16" },
                    { key: "184", doc_count: "15" },
                    { key: "371", doc_count: "15" },
                    { key: "416", doc_count: "15" },
                    { key: "444", doc_count: "15" },
                    { key: "660", doc_count: "15" },
                    { key: "302", doc_count: "14" },
                    { key: "355", doc_count: "14" },
                    { key: "374", doc_count: "14" },
                    { key: "378", doc_count: "14" },
                    { key: "581", doc_count: "14" },
                    { key: "629", doc_count: "14" },
                    { key: "640", doc_count: "14" },
                    { key: "155", doc_count: "13" },
                    { key: "219", doc_count: "13" },
                    { key: "238", doc_count: "13" },
                    { key: "275", doc_count: "13" },
                    { key: "367", doc_count: "13" },
                    { key: "381", doc_count: "13" },
                    { key: "482", doc_count: "13" },
                    { key: "570", doc_count: "13" },
                    { key: "580", doc_count: "13" },
                    { key: "582", doc_count: "13" },
                    { key: "356", doc_count: "12" },
                    { key: "360", doc_count: "12" },
                    { key: "375", doc_count: "12" },
                    { key: "382", doc_count: "12" },
                    { key: "401", doc_count: "12" },
                    { key: "402", doc_count: "12" },
                    { key: "419", doc_count: "12" },
                    { key: "520", doc_count: "12" },
                    { key: "585", doc_count: "12" },
                    { key: "667", doc_count: "12" },
                    { key: "213", doc_count: "11" },
                    { key: "448", doc_count: "11" },
                    { key: "484", doc_count: "11" },
                    { key: "521", doc_count: "11" },
                    { key: "668", doc_count: "11" },
                    { key: "234", doc_count: "10" },
                    { key: "243", doc_count: "10" },
                    { key: "303", doc_count: "10" },
                    { key: "334", doc_count: "10" },
                    { key: "410", doc_count: "10" },
                    { key: "461", doc_count: "10" },
                    { key: "489", doc_count: "10" },
                    { key: "658", doc_count: "10" },
                    { key: "665", doc_count: "10" },
                    { key: "684", doc_count: "10" },
                    { key: "232", doc_count: "9" },
                    { key: "233", doc_count: "9" },
                    { key: "301", doc_count: "9" },
                    { key: "304", doc_count: "9" },
                    { key: "390", doc_count: "9" },
                    { key: "418", doc_count: "9" },
                    { key: "438", doc_count: "9" },
                    { key: "449", doc_count: "9" },
                    { key: "523", doc_count: "9" },
                    { key: "524", doc_count: "9" },
                    { key: "583", doc_count: "9" },
                    { key: "633", doc_count: "9" },
                    { key: "198", doc_count: "8" },
                    { key: "220", doc_count: "8" },
                    { key: "256", doc_count: "8" },
                    { key: "366", doc_count: "8" },
                    { key: "373", doc_count: "8" },
                    { key: "376", doc_count: "8" },
                    { key: "405", doc_count: "8" },
                    { key: "498", doc_count: "8" },
                    { key: "579", doc_count: "8" },
                    { key: "605", doc_count: "8" },
                    { key: "659", doc_count: "8" },
                    { key: "278", doc_count: "7" },
                    { key: "300", doc_count: "7" },
                    { key: "336", doc_count: "7" },
                    { key: "478", doc_count: "7" },
                    { key: "586", doc_count: "7" },
                    { key: "587", doc_count: "7" },
                    { key: "612", doc_count: "7" },
                    { key: "621", doc_count: "7" },
                    { key: "622", doc_count: "7" },
                    { key: "670", doc_count: "7" },
                    { key: "671", doc_count: "7" },
                    { key: "218", doc_count: "6" },
                    { key: "235", doc_count: "6" },
                    { key: "240", doc_count: "6" },
                    { key: "330", doc_count: "6" },
                    { key: "338", doc_count: "6" },
                    { key: "364", doc_count: "6" },
                    { key: "377", doc_count: "6" },
                    { key: "408", doc_count: "6" },
                    { key: "471", doc_count: "6" },
                    { key: "532", doc_count: "6" },
                    { key: "533", doc_count: "6" },
                    { key: "602", doc_count: "6" },
                    { key: "620", doc_count: "6" },
                    { key: "663", doc_count: "6" },
                    { key: "672", doc_count: "6" },
                    { key: "688", doc_count: "6" },
                    { key: "189", doc_count: "5" },
                    { key: "236", doc_count: "5" },
                    { key: "241", doc_count: "5" },
                    { key: "242", doc_count: "5" },
                    { key: "247", doc_count: "5" },
                    { key: "314", doc_count: "5" },
                    { key: "339", doc_count: "5" },
                    { key: "379", doc_count: "5" },
                    { key: "384", doc_count: "5" },
                    { key: "424", doc_count: "5" },
                    { key: "426", doc_count: "5" },
                    { key: "451", doc_count: "5" },
                    { key: "472", doc_count: "5" },
                    { key: "491", doc_count: "5" },
                    { key: "546", doc_count: "5" },
                    { key: "594", doc_count: "5" },
                    { key: "638", doc_count: "5" },
                    { key: "690", doc_count: "5" },
                    { key: "254", doc_count: "4" },
                    { key: "292", doc_count: "4" },
                    { key: "353", doc_count: "4" },
                    { key: "389", doc_count: "4" },
                    { key: "464", doc_count: "4" },
                    { key: "477", doc_count: "4" },
                    { key: "539", doc_count: "4" },
                    { key: "592", doc_count: "4" },
                    { key: "595", doc_count: "4" },
                    { key: "606", doc_count: "4" },
                    { key: "608", doc_count: "4" },
                    { key: "609", doc_count: "4" },
                    { key: "173", doc_count: "3" },
                    { key: "291", doc_count: "3" },
                    { key: "293", doc_count: "3" },
                    { key: "329", doc_count: "3" },
                    { key: "331", doc_count: "3" },
                    { key: "386", doc_count: "3" },
                    { key: "393", doc_count: "3" },
                    { key: "501", doc_count: "3" },
                    { key: "516", doc_count: "3" },
                    { key: "607", doc_count: "3" },
                    { key: "635", doc_count: "3" },
                    { key: "691", doc_count: "3" },
                    { key: "151", doc_count: "2" },
                    { key: "192", doc_count: "2" },
                    { key: "211", doc_count: "2" },
                    { key: "245", doc_count: "2" },
                    { key: "257", doc_count: "2" },
                    { key: "277", doc_count: "2" },
                    { key: "282", doc_count: "2" },
                    { key: "295", doc_count: "2" },
                    { key: "312", doc_count: "2" },
                    { key: "332", doc_count: "2" },
                    { key: "351", doc_count: "2" },
                    { key: "396", doc_count: "2" },
                    { key: "399", doc_count: "2" },
                    { key: "409", doc_count: "2" },
                    { key: "411", doc_count: "2" },
                    { key: "412", doc_count: "2" },
                    { key: "417", doc_count: "2" },
                    { key: "428", doc_count: "2" },
                    { key: "431", doc_count: "2" },
                    { key: "473", doc_count: "2" },
                    { key: "479", doc_count: "2" },
                    { key: "485", doc_count: "2" },
                    { key: "494", doc_count: "2" },
                    { key: "497", doc_count: "2" },
                    { key: "499", doc_count: "2" },
                    { key: "502", doc_count: "2" },
                    { key: "505", doc_count: "2" },
                    { key: "525", doc_count: "2" },
                    { key: "541", doc_count: "2" },
                    { key: "543", doc_count: "2" },
                    { key: "613", doc_count: "2" },
                    { key: "617", doc_count: "2" },
                    { key: "626", doc_count: "2" },
                    { key: "641", doc_count: "2" },
                    { key: "655", doc_count: "2" },
                    { key: "682", doc_count: "2" },
                    { key: "683", doc_count: "2" },
                    { key: "685", doc_count: "2" },
                    { key: "191", doc_count: "1" },
                    { key: "193", doc_count: "1" },
                    { key: "224", doc_count: "1" },
                    { key: "228", doc_count: "1" },
                    { key: "248", doc_count: "1" },
                    { key: "249", doc_count: "1" },
                    { key: "250", doc_count: "1" },
                    { key: "251", doc_count: "1" },
                    { key: "252", doc_count: "1" },
                    { key: "253", doc_count: "1" },
                    { key: "255", doc_count: "1" },
                    { key: "258", doc_count: "1" },
                    { key: "259", doc_count: "1" },
                    { key: "260", doc_count: "1" },
                    { key: "261", doc_count: "1" },
                    { key: "262", doc_count: "1" },
                    { key: "263", doc_count: "1" },
                    { key: "264", doc_count: "1" },
                    { key: "265", doc_count: "1" },
                    { key: "266", doc_count: "1" },
                    { key: "267", doc_count: "1" },
                    { key: "268", doc_count: "1" },
                    { key: "269", doc_count: "1" },
                    { key: "270", doc_count: "1" },
                    { key: "271", doc_count: "1" },
                    { key: "272", doc_count: "1" },
                    { key: "281", doc_count: "1" },
                    { key: "283", doc_count: "1" },
                    { key: "285", doc_count: "1" },
                    { key: "290", doc_count: "1" },
                    { key: "311", doc_count: "1" },
                    { key: "313", doc_count: "1" },
                    { key: "387", doc_count: "1" },
                    { key: "406", doc_count: "1" },
                    { key: "407", doc_count: "1" },
                    { key: "413", doc_count: "1" },
                    { key: "450", doc_count: "1" },
                    { key: "452", doc_count: "1" },
                    { key: "465", doc_count: "1" },
                    { key: "466", doc_count: "1" },
                    { key: "468", doc_count: "1" },
                    { key: "469", doc_count: "1" },
                    { key: "470", doc_count: "1" },
                    { key: "474", doc_count: "1" },
                    { key: "483", doc_count: "1" },
                    { key: "492", doc_count: "1" },
                    { key: "500", doc_count: "1" },
                    { key: "511", doc_count: "1" },
                    { key: "512", doc_count: "1" },
                    { key: "513", doc_count: "1" },
                    { key: "514", doc_count: "1" },
                    { key: "515", doc_count: "1" },
                    { key: "517", doc_count: "1" },
                    { key: "526", doc_count: "1" },
                    { key: "534", doc_count: "1" },
                    { key: "535", doc_count: "1" },
                    { key: "536", doc_count: "1" },
                    { key: "544", doc_count: "1" },
                    { key: "545", doc_count: "1" },
                    { key: "547", doc_count: "1" },
                    { key: "548", doc_count: "1" },
                    { key: "549", doc_count: "1" },
                    { key: "552", doc_count: "1" },
                    { key: "557", doc_count: "1" },
                    { key: "559", doc_count: "1" },
                    { key: "560", doc_count: "1" },
                    { key: "561", doc_count: "1" },
                    { key: "563", doc_count: "1" },
                    { key: "566", doc_count: "1" },
                    { key: "571", doc_count: "1" },
                    { key: "572", doc_count: "1" },
                    { key: "573", doc_count: "1" },
                    { key: "574", doc_count: "1" },
                    { key: "588", doc_count: "1" },
                    { key: "589", doc_count: "1" },
                    { key: "590", doc_count: "1" },
                    { key: "591", doc_count: "1" },
                    { key: "593", doc_count: "1" },
                    { key: "599", doc_count: "1" },
                    { key: "614", doc_count: "1" },
                    { key: "615", doc_count: "1" },
                    { key: "616", doc_count: "1" },
                    { key: "623", doc_count: "1" },
                    { key: "651", doc_count: "1" },
                    { key: "661", doc_count: "1" },
                    { key: "681", doc_count: "1" },
                ],
            },
        },
    },
};
