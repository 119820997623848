import { portfolioTeasersLoaded, State } from "./types";

export const namespaced = true;

export const state: State = {
    debug: process.env.NODE_ENV === "development",
    mArticleTeaserCount: 0,
    mArticleTeasersLoaded: false,
    loadedMArticleTeasers: 0,
    portfolioTeaserCounts: {},
    portfolioTeasersLoaded: {},
    loadedPortfolioTeasers: {},
};

export const getters = {
    mArticleTeaserCount: (state: State): number => {
        return state.mArticleTeaserCount;
    },

    mArticleTeasersLoaded: (state: State): boolean => {
        return state.mArticleTeasersLoaded;
    },

    loadedMArticleTeasers: (state: State): number => {
        return state.loadedMArticleTeasers;
    },

    portfolioTeaserCounts: (state: State): object => {
        return state.portfolioTeaserCounts;
    },

    portfolioTeasersLoaded: (state: State): object => {
        return state.portfolioTeasersLoaded;
    },

    loadedPortfolioTeasers: (state: State): object => {
        return state.loadedPortfolioTeasers;
    },
};

export const mutations = {
    SET_M_ARTICLE_TEASERS_LOADED(state: State, loaded: boolean) {
        state.mArticleTeasersLoaded = loaded;
    },

    SET_M_ARTICLE_TEASER_COUNT(state: State, count: number) {
        state.mArticleTeaserCount = count;
    },

    SET_PORTFOLIO_TEASERS_LOADED(state: State, payload: portfolioTeasersLoaded) {
        state.portfolioTeasersLoaded = payload;
    },

    SET_PORTFOLIO_TEASER_COUNTS(state: State, payload: any) {
        //TODO: add teasercount type
        // @ts-ignore
        state.portfolioTeaserCounts[payload.id] = payload.count;
    },

    SET_LOADED_PORTFOLIO_TEASERS(state: State, payload: any) {
        //TODO: add portofolioteaser type
        // @ts-ignore
        state.loadedPortfolioTeasers[payload.id] = payload.value;
    },
};

export const actions = {
    setMArticleTeasersLoaded({ commit }: any, loaded: boolean) {
        commit("SET_M_ARTICLE_TEASERS_LOADED", loaded);
    },

    setMArticleTeaserCount({ commit }: any, count: number) {
        commit("SET_M_ARTICLE_TEASER_COUNT", count);
    },

    increaseLoadedMArticles({ state, commit }: any) {
        state.loadedMArticleTeasers += 1;

        commit("SET_M_ARTICLE_TEASERS_LOADED", state.mArticleTeaserCount === state.loadedMArticleTeasers);
    },

    increaseLoadedPortfolio({ state, commit }: any, id: string) {
        const loaded = Object.assign({}, state.portfolioTeasersLoaded);
        const loadedTeasers = Object.assign({}, state.loadedPortfolioTeasers);

        loadedTeasers[id] += 1;

        commit("SET_LOADED_PORTFOLIO_TEASERS", { id, value: loadedTeasers[id] });

        loaded[id] = state.portfolioTeaserCounts[id] === state.loadedPortfolioTeasers[id];
        commit("SET_PORTFOLIO_TEASERS_LOADED", loaded);
    },

    setPortfolioTeasersLoaded({ state, commit }: any, payload: any) {
        const loaded = Object.assign({}, state.portfolioTeasersLoaded);

        loaded[payload.id] = payload.loaded;
        commit("SET_PORTFOLIO_TEASERS_LOADED", loaded);
    },

    setPortfolioTeaserCount({ commit }: any, payload: any) {
        commit("SET_PORTFOLIO_TEASER_COUNTS", payload);
    },

    initPortfolioTeaserGroups({ state, commit }: any, id: string) {
        const loaded = Object.assign({}, state.portfolioTeasersLoaded);

        loaded[id] = false;

        commit("SET_LOADED_PORTFOLIO_TEASERS", { id, value: 0 });
        commit("SET_PORTFOLIO_TEASERS_LOADED", loaded);
    },
};
