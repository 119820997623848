/**
 * Initialize script when DOM is fully loaded
 *
 * @method
 */
export default (callback: (event?: Event) => void): void => {
    if (document.readyState === "complete") {
        callback();
    } else {
        document.addEventListener(
            "DOMContentLoaded",
            (event) => {
                callback(event);
            },
            { once: true }
        );
    }
};
