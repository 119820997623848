import { State } from "./types";

export const state: State = {
    debug: process.env.NODE_ENV === "development",
    activeIdx: 0,
    subNavigationOpen: false,
    searchAPI: "/__api.cfm?_action=search.api.autocomplete",
    firstCollision: false,
    secondCollision: false,
    tippingPoint1: 0,
    tippingPoint2: 0,
    roomForNavigation: true,
    metaNavIconSize: 42,
    navSpacingSize: 32,
    metaNavSpacingSize: 64,
    SEARCH_IDX: 20,
    MYDSPACE_IDX: 21,
    LANGUAGES_IDX: 22,
    accordionOpenIds: [],
    resizeAccordions: false,
};

export const namespaced = true;

export const getters = {
    getResizeAccordions: (state: State): boolean => {
        return state.resizeAccordions;
    },

    activeIdx: (state: State): number => {
        return state.activeIdx;
    },

    roomForNavigation: (state: State): boolean => {
        return state.roomForNavigation;
    },

    subNavigationOpen: (state: State): boolean => {
        return state.subNavigationOpen;
    },

    firstCollision: (state: State): boolean => {
        return state.firstCollision;
    },

    secondCollision: (state: State): boolean => {
        return state.secondCollision;
    },

    tippingPoint1: (state: State): number => {
        return state.tippingPoint1;
    },

    metaNavIconSize: (state: State): number => {
        return state.metaNavIconSize;
    },

    metaNavSpacingSize: (state: State): number => {
        return state.metaNavSpacingSize;
    },

    navSpacingSize: (state: State): number => {
        return state.navSpacingSize;
    },

    SEARCH_IDX: (state: State): number => {
        return state.SEARCH_IDX;
    },

    MYDSPACE_IDX: (state: State): number => {
        return state.MYDSPACE_IDX;
    },

    LANGUAGES_IDX: (state: State): number => {
        return state.LANGUAGES_IDX;
    },

    accordionOpenIds: (state: State): number[] => {
        return state.accordionOpenIds;
    },
};

export const mutations = {
    SET_ACTIVE_IDX(state: State, idx: number) {
        state.activeIdx = idx;
    },

    RESET_ACTIVE_IDX(state: State) {
        state.activeIdx = 0;
    },

    SET_SUB_NAVIGATION_OPEN(state: State, open: boolean) {
        state.subNavigationOpen = open;
    },

    SET_FIRST_COLLISION(state: State, collision: boolean) {
        state.firstCollision = collision;
    },

    SET_SECOND_COLLISION(state: State, collision: boolean) {
        state.secondCollision = collision;
    },

    SET_TIPPING_POINT_1(state: State, tippingPoint: number) {
        state.tippingPoint1 = tippingPoint;
    },

    SET_TIPPING_POINT_2(state: State, tippingPoint: number) {
        state.tippingPoint2 = tippingPoint;
    },

    SET_ROOM_FOR_NAVIGATION(state: State, isMobile: boolean) {
        state.roomForNavigation = isMobile
            ? false
            : window.innerWidth > state.tippingPoint2 || window.innerWidth > state.tippingPoint1;
    },

    SET_ACCORDION_OPEN(state: State, id: number) {
        const idsCopy = state.accordionOpenIds.slice();

        if (idsCopy.indexOf(id) < 0) {
            idsCopy.push(id);
        } else {
            idsCopy.splice(idsCopy.indexOf(id), 1);
        }

        state.accordionOpenIds = idsCopy;
    },

    SET_RESIZE_ACCORDIONS(state: State, resize: boolean) {
        state.resizeAccordions = resize;
    },
};

export const actions = {
    resizeAccordions({ commit }: any, resize: boolean) {
        commit("SET_RESIZE_ACCORDIONS", resize);
    },

    setAccordionOpen({ commit }: any) {
        commit("SET_ACCORDION_OPEN");
    },

    setSubNavigationOpen({ commit }: any, open: boolean) {
        commit("SET_SUB_NAVIGATION_OPEN", open);
    },

    setActiveIdx({ commit }: any, idx: number) {
        commit("SET_ACTIVE_IDX", idx);
    },

    resetActiveIdx({ commit }: any) {
        commit("RESET_ACTIVE_IDX");
    },

    setFirstCollision({ commit }: any, collision: boolean) {
        commit("SET_FIRST_COLLISION", collision);
    },

    setSecondCollision({ commit }: any, collision: boolean) {
        commit("SET_SECOND_COLLISION", collision);
    },

    setTippingPoint1({ commit }: any, tippingPoint1: number) {
        commit("SET_TIPPING_POINT_1", tippingPoint1);
    },

    setTippingPoint2({ commit }: any, tippingPoint2: number) {
        commit("SET_TIPPING_POINT_2", tippingPoint2);
    },

    setRoomForNavigation({ commit }: any, isMobile: number) {
        commit("SET_ROOM_FOR_NAVIGATION", isMobile);
    },
};
