import { snakeCase } from "lodash";

export type requestParams = {
    [key: string]: unknown;
};

export const transformParams = (params: requestParams): requestParams => {
    const transformedParams: requestParams = {};

    Object.entries(params).forEach((entry) => {
        transformedParams[snakeCase(entry[0])] = entry[1];
    });

    return transformedParams;
};
