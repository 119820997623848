//TODO: Add types in node version update branch (already defined there)
// @ts-ignore
export const getResultCountForSingleFilter = ({ filterResults, filterTerm }) => {
    // @ts-ignore
    const resultCount = filterResults.filter((result) => {
        return result.filterterms.indexOf(filterTerm) > -1;
    });

    return resultCount.length;
};
