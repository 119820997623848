import { FilterSections } from "@/components/organisms/filter-multi/types";
import { ResultGroups } from "@/store/modules/filterStore/types";

export const getResultGroups = (filterSections: FilterSections | null = null): ResultGroups | undefined => {
    if (!filterSections) return;

    const resultGroups: ResultGroups = {};

    Object.keys(filterSections).forEach((filterSection) => {
        Object.values(filterSections[filterSection].groups).forEach((filterGroup) => {
            resultGroups[filterGroup.id] = [];
        });
    });

    return resultGroups;
};
