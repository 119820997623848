type CategoryParams = {
    activeCategories: string[];
    category: string;
};

export const updateCategory = (params: CategoryParams): string[] => {
    if (!params.activeCategories || !params.category) return [];
    const categoryIndex = params.activeCategories.indexOf(params.category);

    if (categoryIndex < 0) {
        params.activeCategories.push(params.category);
    } else {
        params.activeCategories.splice(categoryIndex, 1);
    }

    return params.activeCategories;
};
